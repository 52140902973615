import { LoaderFunctionArgs, json } from "@remix-run/cloudflare";
import { Link, NavLink, Outlet, useLoaderData } from "@remix-run/react";
import PopoverAPY from "~/components/popover-apy";
import { fetchPrices } from "~/lib/astrolescent";
import { setDbFromContext } from "~/lib/db";
import { getPairs } from "~/lib/models/pair";
import { classNames, onIconError, prettyValue, getQuoteToken, getIconUrl } from "~/lib/util";

type Pair = {
  baseToken: {
    address: string;
    symbol: string;
    name: string;
    icon: string;
  };
  tvl: number;
  apy: number;
};

export async function loader({ context }: LoaderFunctionArgs) {
  setDbFromContext(context);

  const allPairs = await getPairs();
  const pairs = allPairs.filter((p) => p.Pair.dexAddress !== "component_rdx1cq34ta4ssvtyxm3vf0l4eleunt7uz9ha5wyzrc965xqafdueadpy4x");
  const prices = await fetchPrices();

  pairs.sort((a, b) => {
    // console.log(a.Pair.address, tvl[a.Pair.address]?.usdAmount, b.Pair.address, tvl[b.Pair.address]?.usdAmount);

    if (a.Pair.tvlUSD < b.Pair.tvlUSD) {
      return 1;
    } else if (a.Pair.tvlUSD > b.Pair.tvlUSD) {
      return -1;
    }

    return 0;
  });

  // calc volumes
  const volume24H = pairs.reduce(
    (total, p) => total + (p.Pair.baseVolume24H * (prices[p.Token.address]?.tokenPriceUSD || 0) + p.Pair.quoteVolume24H * prices[getQuoteToken(p.Pair.dexAddress).address]?.tokenPriceUSD || 0) / 2,
    0,
  );

  // calc tvl
  const totalTVL = pairs.reduce((total, p) => total + (p.Pair.tvlUSD || 0), 0);

  return json({
    pairs,
    totalTVL,
    volume24H,
  });
}

export default function LiquidityAdd() {
  const { pairs, totalTVL, volume24H } = useLoaderData<typeof loader>();

  return (
    <div className="mx-4 mb-6 xl:container xl:mx-auto">
      <div className="flex items-center justify-between">
        <h1 className="py-4 text-left text-3xl font-bold text-white lg:p-8 lg:text-4xl">Pools</h1>
        <Link to="/liquidity/create" className="btn-white-small">
          Create New Pool
        </Link>
      </div>

      <dl className="lg:items-top mb-4 flex flex-col space-y-4 lg:mb-8 lg:flex-row lg:space-x-4 lg:space-y-0">
        <div className="flex grow flex-row items-center justify-between rounded-xl bg-primary-800 px-4 py-2 text-white lg:flex-col lg:items-start lg:justify-normal lg:space-y-4 lg:rounded-2xl lg:p-8">
          <dt className="truncate font-medium text-primary-100 lg:text-sm">Total Value Locked</dt>
          <dd className="font-semibold text-white lg:mt-1 lg:text-3xl">${prettyValue(totalTVL)}</dd>
        </div>
        <div className="flex grow flex-row items-center justify-between rounded-xl bg-primary-800 px-4 py-2 text-white lg:flex-col lg:items-start lg:justify-normal lg:space-y-4 lg:rounded-2xl lg:p-8">
          <dt className="truncate font-medium text-primary-100 lg:text-sm">24H Volume</dt>
          <dd className="font-semibold text-white lg:mt-1 lg:text-3xl">${prettyValue(volume24H)}</dd>
        </div>
        <div className="flex grow flex-row items-center justify-between rounded-xl bg-primary-800 px-4 py-2 text-white lg:flex-col lg:items-start lg:justify-normal lg:space-y-4 lg:rounded-2xl lg:p-8">
          <dt className="truncate font-medium text-primary-100 lg:text-sm">Pools</dt>
          <dd className="font-semibold text-white lg:mt-1 lg:text-3xl">{pairs.length}</dd>
        </div>
      </dl>

      <section className="lg:items-top flex flex-col-reverse lg:flex-row lg:space-x-4">
        <div className="mt-4 grow space-y-4 rounded-xl bg-primary-800 p-4 text-white lg:mt-0 lg:rounded-2xl lg:p-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="pl-0 pr-3 text-left text-sm font-semibold text-primary-100 lg:py-3.5 lg:pl-4">
                  Pairs
                </th>
                <th scope="col" className="hidden px-3 pr-1 text-left text-sm font-semibold text-primary-100 lg:table-cell lg:py-3.5">
                  TVL
                </th>
                <th scope="col" className="hidden px-3 pl-1 text-left text-sm font-semibold text-primary-100 lg:table-cell lg:py-3.5"></th>
                <th scope="col" className="hidden px-3 text-left text-sm font-semibold text-primary-100 lg:table-cell lg:py-3.5">
                  <PopoverAPY title="7D ALR" timespan="This ALR is based on the data of the last seven days." />
                </th>
                <th scope="col" className="hidden px-3 text-left text-sm font-semibold text-primary-100 lg:table-cell lg:py-3.5">
                  <PopoverAPY title="ALR" timespan="This ALR is based on the data since the creation of the pair." />
                </th>
                <th scope="col" className="relative pl-3 pr-0 lg:py-3.5 lg:pr-4"></th>
              </tr>
            </thead>
            <tbody className="lg:divide-y lg:divide-primary-200">
              {pairs.map((pair) => {
                const quoteToken = getQuoteToken(pair.Pair.dexAddress);

                return (
                  <>
                    <tr key={pair.Token.address} className={classNames("hover:bg-primary-700")}>
                      <td className="w-full max-w-0 py-4 pl-0 pr-3 font-bold max-sm:pb-2 sm:w-auto sm:max-w-none sm:pl-0 lg:pl-4">
                        <div className="flex items-center">
                          <div className="relative h-8 w-12">
                            <img className="absolute left-4 top-0 h-8 w-8 flex-shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl)} onError={(evt) => onIconError(evt)} alt="" />
                            <img className="absolute left-0 top-0 h-8 w-8 flex-shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl)} onError={(evt) => onIconError(evt)} alt="" />
                          </div>
                          <div className="ml-2 grow lg:ml-4">
                            <span>
                              {pair.Token.symbol} / {quoteToken.symbol}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="hidden px-3 py-4 pr-1 text-sm font-medium text-primary-200 sm:table-cell">
                        <p className="flex items-center space-x-2">
                          <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                          <span>
                            {prettyValue(pair.Pair.baseTVL || 0)} {pair.Token.symbol}
                          </span>
                        </p>
                        <p className="flex items-center space-x-2">
                          <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                          <span>
                            {prettyValue(pair.Pair.quoteTVL || 0)} {quoteToken.symbol}
                          </span>
                        </p>
                      </td>
                      <td className="hidden px-3 py-4 pl-1 font-medium text-primary-200 max-sm:pb-2 sm:table-cell">${prettyValue(pair.Pair.tvlUSD || 0)}</td>
                      <td className="hidden px-3 py-4 pr-1 text-sm font-medium text-primary-200 sm:table-cell">
                        <p className="flex items-center space-x-2">
                          <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                          <span>{Math.round(pair.Pair.baseAPY7D * 10000) / 100}%</span>
                        </p>
                        <p className="flex items-center space-x-2">
                          <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                          <span>{Math.round(pair.Pair.quoteAPY7D * 10000) / 100}%</span>
                        </p>
                      </td>
                      <td className="hidden px-3 py-4 pr-1 text-sm font-medium text-primary-200 sm:table-cell">
                        <p className="flex space-x-2">
                          <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                          <span>{Math.round(pair.Pair.baseAPY * 10000) / 100}%</span>
                        </p>
                        <p className="flex space-x-2">
                          <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                          <span>{Math.round(pair.Pair.quoteAPY * 10000) / 100}%</span>
                        </p>
                      </td>
                      <td className="py-4 pl-3 pr-0 text-right text-sm font-medium max-sm:pb-2 sm:pr-0 lg:pr-4">
                        <NavLink
                          to={`/liquidity/add/${pair.Token.address}?direction=base`}
                          className={({ isActive }) =>
                            isActive
                              ? "text-md flex items-center justify-center rounded-full bg-primary-600 py-3 font-medium uppercase leading-none tracking-wider text-primary-100"
                              : "text-md rounded-full border border-white px-4 py-3 font-medium uppercase leading-none tracking-wider text-white hover:border-primary-500 hover:bg-primary-500"
                          }
                        >
                          {({ isActive, isPending }) => (
                            <>
                              {isActive ? (
                                <>
                                  {/* <span>Adding</span> */}
                                  <svg className="h-4 w-4" viewBox="2 2 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M10.986 5.456a.608.608 0 0 0-.827 0 .564.564 0 0 0 0 .809l3.233 3.16H5.29a.579.579 0 0 0-.584.572.58.58 0 0 0 .584.572h8.102l-3.233 3.16a.564.564 0 0 0 0 .81c.11.107.257.167.414.167.155 0 .302-.06.413-.168l4.072-3.982a.778.778 0 0 0 0-1.119l-4.072-3.98z"
                                      fill="currentColor"
                                      fillRule="nonzero"
                                    ></path>
                                  </svg>
                                </>
                              ) : (
                                "Add"
                              )}
                            </>
                          )}
                        </NavLink>
                      </td>
                    </tr>
                    <tr className="border-b border-b-primary-200 lg:hidden">
                      <td>
                        <table className="mb-2 ml-14 w-full">
                          <tr className="mt-2">
                            <td className="w-1/2 text-sm text-primary-100">TVL</td>
                            <td className="w-1/2">${prettyValue(pair.Pair.tvlUSD || 0)}</td>
                          </tr>
                          <tr></tr>
                          <tr className="mt-2 text-sm text-primary-100">
                            <td>7D ALR</td>
                            <td>ALR</td>
                          </tr>
                          <tr>
                            <td>
                              <p className="flex items-center space-x-2">
                                <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                                <span>{Math.round(pair.Pair.baseAPY7D * 10000) / 100}%</span>
                              </p>
                              <p className="flex items-center space-x-2">
                                <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                                <span>{Math.round(pair.Pair.quoteAPY7D * 10000) / 100}%</span>
                              </p>
                            </td>
                            <td>
                              <p className="flex items-center space-x-2">
                                <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(pair.Token.iconUrl, 16)} onError={(evt) => onIconError(evt)} alt="" />
                                <span>{Math.round(pair.Pair.baseAPY * 10000) / 100}%</span>
                              </p>
                              <p className="flex items-center space-x-2">
                                <img className="h-4 w-4 flex-shrink-0 rounded-full bg-white" src={getIconUrl(quoteToken.iconUrl, 16)} alt="" />
                                <span>{Math.round(pair.Pair.quoteAPY * 10000) / 100}%</span>
                              </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>

        <Outlet />
      </section>
    </div>
  );
}
